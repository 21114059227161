.hamburger-button {
    margin: 0px;
    float: right;
}

.hamburger-show, .hamburger-show-hide {
    color: white;
    transition: .3s;
    text-align: right;
}

.hamburger-show {
    opacity: 1;
}

.hamburger-show-hide {
    opacity: 0;
    pointer-events:none;
}

.hamburger-links {
    padding-top: 50px;
    padding-right: 10px;
    line-height: 25px;
    margin: 5px;
}

.hamburger-links:hover {
    cursor: pointer;
}
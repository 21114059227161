body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #131518;
  background-color:#0e1425; */
  /* background: rgb(17,25,45);background: rgb(17,25,46);
  background: linear-gradient(90deg, rgba(17,25,46,1) 0%, rgba(27,40,77,1) 32%, rgba(24,38,79,1) 51%, rgba(29,35,51,1) 100%); */
  /* background: rgb(10,19,42);
  background: linear-gradient(90deg, rgba(10,19,42,1) 0%, rgba(18,25,47,1) 34%, rgba(11,19,45,1) 56%, rgba(21,25,37,1) 100%); */
  background: rgb(9,17,40);
  background: linear-gradient(90deg, rgba(9,17,40,1) 0%, rgba(14,23,50,1) 50%, rgba(8,17,38,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.experience-box {
    /* background-color: #191a1a; */
    background-color: #171f3b;
    border-radius: 15px;
    width: 60%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin: 0px;
    font-family: 'Montserrat';

    transition: .4s;
}

.experience-box:hover {
    transform: scale(1.015);
    box-shadow: 1px 1px 25px #121111;

    transition: .2s;
}

.experience-name, .experience-position, .experience-location, .experience-year {
    padding-top: 8px;
}

.experience-box-header {
    padding: 15px 25px 20px 25px;
}
.header-line-one {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.header-line-two {
    display: flex;
    flex-wrap: wrap;
}

.experience-name {
    color: white;
    margin: 0px
    
}

.experience-position {
    margin: 0px;
    color: white;
    font-size: 20px;
}

.experience-location {
    margin: 0px;
    margin-left: auto;
    font-size: 20px;
    color: #d0d1cb;
}

.experience-year {
    margin: 0px;
    margin-left: auto;
    font-size: 18px;
    color: #9d9d9d;
}

.experience-box-text {
    margin: 0px;
    padding: 0px 50px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    text-align: left;
    color: #d0d1cb;
}

@media (max-width: 500px){
    .experience-name {
        font-size: 25px;
    }
    .experience-position, .experience-location, .experience-year {
        font-size: 18px;
    }
    .experience-box-text{  
       padding: 0px 20px 20px 10px;
       font-size: 15px;
    } 
}
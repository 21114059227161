.skills-section {
    display: flex;
    flex-direction: column;
    align-items: center; 

}

.skills-heading {
    color: white;
    color: #d0d1cb;
    font-size: 36px;
}
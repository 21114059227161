.project-box {
    background-color: #171f3b;
    border-radius: 15px;
    width: 60%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin: 0px;
    font-family: 'Montserrat';

    transition: .4s;
}

.project-box:hover {
    transform: scale(1.015);
    box-shadow: 1px 1px 25px #121111;
    transition: .2s;
}

a {
    text-decoration: none;
}

.project-box-header {
    padding: 15px 25px 20px 25px;
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.header-line-one {
    display: flex;
    flex-wrap: wrap;
}

.project-name, .project-language, .project-year-created {
    margin: 0px;
}

.project-name {
    font-size: 28px;
    color: white;
    flex-basis: 100%;
}

.project-right-side {
    display: flex;
    margin-left: auto;
    padding-top: 5px;
    padding-left: 10px;
}
.project-language {
    font-size: 20px;
    font-weight: 100px;
    color: #d0d1cb;
}

.project-url {
    font-size: 14px;
    color: #79b9e4;
    margin-top: 5px;
    text-decoration: underline;
}

.project-url:hover {
    color: #64a6d2;
}

.project-year-created { 
    margin-left: auto;
    padding-top: 5px;
    font-size: 18px;
    color: #9d9d9d;;
}

.project-box-text {
    margin: 0px;
    padding: 0px 50px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    text-align: left;
    color: #d0d1cb;
}

.bullet-list {
    margin: 0px;
}

.bullet {
    font-size: 17px;
    line-height: 28px;
}

@media (max-width: 500px){
    .project-right-side {
        padding-top: 15px;
    }
    .project-box-text{  
       padding: 0px 20px 20px 10px;
    } 
}
.home-text-container {
    color: white;
    text-align: center;
    margin: 0 auto 100px auto;
    height: 100vh;
    min-height: 1000px;
    border-bottom: 5px solid #171d36;
}

.home-text {
    font-size: 50px;
    color: white;
    /* padding-top: 35vh; */
    font-family: "Montserrat";
    margin: 0px;
    padding: 30vh 20px 0px 20px;
    /* text-shadow: 5px 5px 50px #d4af90; */
    text-shadow: 0 .5px 0 #ccc,
               0 1px 0 #c9c9c9,
               0 1.5px 0 #bbb,
               0 2px 0 #b9b9b9,
               0 1.5px 0 #aaa,
               0 3px .5px rgba(0,0,0,.1),
               0 0 2.5px rgba(0,0,0,.1),
               0 .5px 1.5px rgba(0,0,0,.3),
               0 1.5px 5px rgba(0,0,0,.2),
               0 2.5px 5px rgba(0,0,0,.25),
               0 5px 5px rgba(0,0,0,.2),
               0 10px 10px rgba(0,0,0,.15);

}

.home-links-hide .home-link-logo1 {
    margin: 500px 50px 0px 50px;
    opacity: 0;
    transition: 1s;
    pointer-events:none;
}

.home-links-show .home-link-logo1 {
    opacity: 1;
    transition: 1s;
    margin: 0px 50px 0px 50px;
    padding-top: max(50px, 10%);
}

.home-links-hide .home-link-logo2 {
    margin: 500px 50px 0px 50px;
    opacity: 0;
    transition: 1s;
    pointer-events:none;
}

.home-links-show .home-link-logo2 {
    opacity: 1;
    transition: 1s;
    margin: 0px 50px 0px 50px;
    padding-top: max(50px, 10%);
}

.home-links-hide .home-link-down-arrow {
    margin: 500px 50px 0px 50px;
    opacity: 0;
    transition: 1s;
    pointer-events:none;
}

.home-links-show .home-link-down-arrow {
    opacity: 1;
    transition: 1s;
    margin: 0px 50px 0px 50px;
    cursor: pointer;
    margin-top: max(150px, 10%);
}

.home-links-show .home-link-logo1:hover {
    /* padding: 10px; */
    transform: translateY(-20px);
    transition: .5s;
}

.home-links-show .home-link-logo2:hover {
    /* padding: 10px; */
    transform: translateY(-20px);
    transition: .5s;
}

.home-links-show .home-link-down-arrow:hover 
{
    padding: 10px;
    transform: translateY(-20px);
    transition: .5s;
}

@media (max-width: 500px){
    .home-text {
        padding-top: 35%;
        font-size: 40px;
    }
}
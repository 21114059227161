.section-header {
    
    /* color: #ddd4ab; */
    /* text-shadow: 5px 5px 50px #5f4e40; */
    text-shadow: 0 .5px 0 #ccc,
               0 1px 0 #c9c9c9,
               0 1.5px 0 #bbb,
               0 2px 0 #b9b9b9,
               0 2.5px 0 #aaa,
               0 3px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 .5px 3px rgba(0,0,0,.3),
               0 1.5px 5px rgba(0,0,0,.2),
               0 2.5px 10px rgba(0,0,0,.25),
               0 5px 10px rgba(0,0,0,.2),
               0 10px 20px rgba(0,0,0,.15);
}


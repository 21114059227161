.image-container {
    margin: 12px;
    width: 143px;
    height: 143px;
    /* background-color: #191a1a; */
    background-color: #171d36;
    border: 1px solid #1f294e;
    border-radius: 50%;
    transition: .15s;
}

.image-container:hover {
    transform: scale(1.015);
    box-shadow: 5px 5px 30px #121111;
    
    transition: .1s;
}

.image-container-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
}

.image {
    max-height: 55px;
    margin: 0px;
}

.image-skill {
    margin: 0px;
    margin-top: 15px;

    color: #d0d1cb;
    font-size: 16px;
}

@media (max-width: 550px){
    .image-container {
        width: 130px;
        height: 130px;
    }

    .image-container-items {
        padding-top: 25px;
    }

    .image {
        max-height: 55px;
    }
    
    .image-skill {
        font-size: 14px;
    }
}
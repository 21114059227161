.about-text-container {
    margin: 0px auto 0px auto;
    text-align: center;
    width: 75%;
    max-width: 1000px;
    padding-bottom: 20px;

    font-family: "Montserrat";
    font-size: 24px;
    color: white;
    line-height: 35px;
}

@media (max-width: 500px){
    .about-text-container {
        font-size: 18px;
    }
}